import React, { Component } from "react"

import styles from "./styles.module.less"

import asset1 from "./img/1-asset-4-d.png"
import asset2 from "./img/1-asset-4-d@2x.png"
import asset3 from "./img/1-asset-4-d@3x.png"

export default class Out extends Component {
  render() {
    return (
      <section className={styles.Out}>
        <div className={styles.areaPadding}>
          <div className={styles.area}>
            <div className={styles.smallArea}>HOW IT WORKED OUT:</div>
            <div className={styles.largeArea}>
              Thesis launched a refreshed brand and from-scratch website. True
              to Bay Equity&apos;s culture of personability, each piece
              resonated with stakeholders and customers alike. Meanwhile, 300+
              Loan Officers are positively giddy about their customizable
              personal pages.
            </div>
          </div>
        </div>
        <div className={styles.conclusion}>
          <div className={styles.h6}>Credits</div>
          <div className={styles.text3}>
            <i>
              Thanks to the Bay Equity Team. AKA the real people who made the
              real work.
              <br />
              <strong>Account/Project Manager:</strong> KC Anderson
              <br />
              <strong>Strategist:</strong> Cher Fuller
              <br />
              <strong>SEO:</strong> Nathan Stenberg
              <br />
              <strong>Designers:</strong> Allison Bowe, Gus Nicklos, Grant
              Stewart, Dala Botha
              <br />
              <strong>Writers:</strong> Tyler Stenson, Spencer Foxworth, Tyler
              Hively, Elizabeth Harney
              <br />
              <strong>Developers:</strong> Michael Barrett, Sonya Trachsel, Alex
              Liang, Spencer Malone, Cemal Richards
            </i>
          </div>
        </div>
      </section>
    )
  }
}
