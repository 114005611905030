import React, { Component } from "react"

import styles from "./styles.module.less"

// import about2 from "./img/about-us-1-17@2x.png"
// import about3 from "./img/about-us-1-17@3x.png"

export default class Design extends Component {
  render() {
    return (
      <section className={styles.Design}>
        <div className={styles.row1}>
          <div className={styles.text}>
            <div className={styles.text1}>
              Designed a clean, modern website.
            </div>
            <div className={styles.text2}>
              Parallax, cinemagraphs, and dynamic content all work together to
              communicate &quot;tech&#8209;forward&quot; without having to
              say&nbsp;it.
            </div>
          </div>
          <div className={styles.img1}></div>
        </div>
        <div className={styles.row2}></div>
      </section>
    )
  }
}
