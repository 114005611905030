import React, { Component } from "react"

import styles from "./styles.module.less"

import Slide from "react-reveal/Reveal"

import circle2 from "./img/bay-equity-circle-mark-white.png"
import circle1 from "./img/bay-equity-circle-mark-white_2.png"
import word from "./img/bedesign.png"
import circle from "./img/becircle.png"
import bground2 from "./img/bground-1-c-87991-c-8-d-344-d-684-cb-71-f-9-b-52-c-757-d-2@2x.png"
import BEgif from "./img/Bay-Equity_Homepage_nodesktop.gif"
import bgicons from "./img/icons_banner.jpg"

export default class Creative extends Component {
  render() {
    return (
      <section className={styles.Creative}>
        <div className={styles.title}>
          <div className={styles.title1}>
            Best-laid plans thrive with best-best
          </div>
          <div className={styles.title2}>CREATIVE</div>
          <div className={styles.title3}>
            Refreshed logo, brand colors, and iconography, giving them a
            sophisticated-but-approachable persona.
          </div>
        </div>
        <div className={styles.row1}>
          <div className={styles.img1}>
            <img className={styles.img1Ratio} src={circle} alt="" />
          </div>
          <div className={styles.img2}>
            <img className={styles.img2Ratio} src={word} alt="" />
          </div>
        </div>
        <div className={styles.row2}>
          <img src={bgicons} alt="" />
        </div>
        <div className={styles.row3}>
          <Slide bottom>
            <div className={styles.text1}>
              Developed a tone that was trustworthy, inspiring, and thoughtful.
              The content was always useful&mdash;but the Loan Officer was
              always the destination.
            </div>
          </Slide>
          <div className={styles.img3}>
            <img src={bground2} srcSet={`${bground2} 2x`} />
          </div>
        </div>
        <div className={styles.block}></div>
        <div className={styles.row4}>
          <img src={BEgif} />
        </div>
      </section>
    )
  }
}
