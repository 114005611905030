import React, { Component } from "react"

import styles from "./styles.module.less"

import Slide from "react-reveal/Reveal"
import Reveal from "react-reveal/Reveal"

import circle1 from "./img/bay-equity-circle-mark-white_2.png"
import circle2 from "./img/bay-equity-circle-mark-white.png"
import word from "./img/baymark.png"
import image1 from "./img/be-image.png"
import image2 from "./img/be-image@2x.png"
import image3 from "./img/be-image@3x.png"

export default class Gif extends Component {
  render() {
    return (
      <section className={styles.Gif}>
        <Reveal>
          <div className={styles.relative}>
            <div className={styles.hero}>
              <Slide bottom>
                <div className={styles.par}>
                  <div className={styles.textsm}>CASE STUDY: BAY EQUITY</div>
                  <div className={styles.textlg}>
                    Big branding doesn&apos;t need to cost your personal
                    relationships.
                  </div>
                </div>
              </Slide>
            </div>
          </div>
        </Reveal>

        <div className={styles.home}>
          <Reveal>
            <div className={styles.txtHome}>
              <Slide bottom>
                <div className={styles.text1}>A new home for Bay Equity.</div>
                <div className={styles.text2}>
                  You&apos;re the rare bird in your industry&mdash;a family-run
                  mortgage company operating on a national stage. You need a
                  brand that lets you flex those family-run roots while
                  inspiring big-league confidence.
                </div>
              </Slide>
            </div>

            <div className={styles.imgSet}>
              <div>
                <img
                  className={styles.imgLarge}
                  src={image2}
                  srcSet={`${image3} 3x`}
                />
              </div>
              <div>
                <img className={styles.imgWord} src={word} />
              </div>
              <div>
                <img
                  className={styles.imgCircle}
                  src={circle1}
                  srcSet={`${circle2} 2x`}
                />
              </div>
            </div>
          </Reveal>
        </div>

        <div className={styles.areaPadding}>
          <Reveal>
            <div className={styles.area}>
              <div className={styles.smallArea}>HOW TO GET AFTER IT:</div>
              <div className={styles.largeArea}>
                <div className={styles.largeText1}>
                  Own the people-first approach that sets you apart.
                </div>
                <div className={styles.largeText2}>
                  Embrace that lovely DNA.
                </div>
                <div className={styles.largeText3}>
                  Then craft a new brand, site, and communication tool to give
                  it life.
                </div>
              </div>
            </div>
          </Reveal>
        </div>

        <div className={styles.customer}>
          <Slide bottom>
            <div className={styles.textCustomer}>
              Basically, give the customers what they need.
              <br />
              But stay true to you.
            </div>
          </Slide>
        </div>
      </section>
    )
  }
}
