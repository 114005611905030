import React, { Component } from "react"

import Reveal from "react-reveal/Reveal"

import Gif from "./components/Gif"
import Strategy from "./components/Strategy"
import Tech from "./components/Tech"
import Creative from "./components/Creative"
import Design from "./components/Design"
import Phone from "./components/Phone"
import Out from "./components/Out"
import Bottom from "./components/Bottom"

export default class Home1 extends Component {
  render() {
    return (
      <div>
        <Reveal>
          <Gif />
        </Reveal>
        <Reveal>
          <Strategy />
        </Reveal>
        <Reveal>
          <Tech />
        </Reveal>
        <Reveal>
          <Creative />
        </Reveal>
        <Reveal>
          <Design />
        </Reveal>
        <Reveal>
          <Phone />
        </Reveal>
        <Reveal>
          <Out />
        </Reveal>
        <Bottom />
      </div>
    )
  }
}

const Filler = () => {
  return <section style={{ height: "500px" }}>stuff</section>
}
