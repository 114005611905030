import React, { Component } from "react"

import Reveal from "react-reveal/Reveal"

import styles from "./styles.module.less"

import img1_1 from "./img/1.png"
import img1_2 from "./img/1@2x.png"
import img1_3 from "./img/1@3x.png"
import img4_1 from "./img/2.png"
import img4_2 from "./img/2@2x.png"
import img4_3 from "./img/2@3x.png"
import img2_1 from "./img/5.png"
import img2_2 from "./img/5@2x.png"
import img2_3 from "./img/5@3x.png"
import img6_1 from "./img/6.png"
import img6_2 from "./img/6@2x.png"
import img6_3 from "./img/6@3x.png"
import img5_1 from "./img/7.png"
import img5_2 from "./img/7@2x.png"
import img5_3 from "./img/7@3x.png"
import img3_1 from "./img/8.png"
import img3_2 from "./img/8@2x.png"
import img3_3 from "./img/8@3x.png"
import img7_1 from "./img/9.png"
import img7_2 from "./img/9@2x.png"
import img7_3 from "./img/9@3x.png"
import img8_1 from "./img/10.png"
import img8_2 from "./img/10@2x.png"
import img8_3 from "./img/10@3x.png"
import img9_1 from "./img/10_2.png"
import img9_2 from "./img/10_2@2x.png"
import img9_3 from "./img/10_2@3x.png"

export default class Phone extends Component {
  render() {
    return (
      <section className={styles.Phones}>
        <div className={styles.row}>
          <Reveal>
            <img
              className={styles.Phone}
              src={img1_2}
              srcSet={`${img1_3} 2x`}
            />
          </Reveal>
          <Reveal>
            <img
              className={styles.Phone}
              src={img2_2}
              srcSet={`${img2_3} 2x`}
            />
          </Reveal>
          <Reveal>
            <img
              className={styles.Phone}
              src={img3_2}
              srcSet={`${img3_3} 2x`}
            />
          </Reveal>
        </div>
        <div className={styles.row}>
          <Reveal>
            <img
              className={styles.Phone}
              src={img4_2}
              srcSet={`${img4_3} 2x`}
            />
          </Reveal>
          <Reveal>
            <img
              className={styles.Phone}
              src={img5_2}
              srcSet={`${img5_3} 2x`}
            />
          </Reveal>
          <Reveal>
            <img
              className={styles.Phone}
              src={img6_2}
              srcSet={`${img6_3} 2x`}
            />
          </Reveal>
        </div>
        <div className={styles.row}>
          <Reveal>
            <img
              className={styles.Phone}
              src={img7_2}
              srcSet={`${img7_3} 2x`}
            />
          </Reveal>
          <Reveal>
            <img
              className={styles.Phone}
              src={img8_2}
              srcSet={`${img8_3} 2x`}
            />
          </Reveal>
          <Reveal>
            <img
              className={styles.Phone}
              src={img9_2}
              srcSet={`${img9_3} 2x`}
            />
          </Reveal>
        </div>
      </section>
    )
  }
}
