import React, { Component } from "react"
import Reveal from "react-reveal/Reveal"
import Slide from "react-reveal/Reveal"

import styles from "./styles.module.less"

import comp1 from "./img/lo-comp-1.png"
import comp2 from "./img/lo-comp-1@2x.png"
import comp3 from "./img/lo-comp-1@3x.png"
import equity1 from "./img/bay-equity-homepage-1.png"
import equity2 from "./img/bay-equity-homepage-1@2x.png"
import equity3 from "./img/bay-equity-homepage-1@3x.png"

export default class Strategy extends Component {
  render() {
    return (
      <section className={styles.Strategy}>
        <Reveal>
          <div className={styles.title}>
            <div className={styles.title1}>
              Strong results start with a strong
            </div>
            <div className={styles.title2}>STRATEGY</div>
            <div className={styles.title3}>
              Put yourself in the shoes of a new homebuyer or a seasoned
              homeowner looking for a change of scenery. Each audience faces a
              unique problem&#8212;
              <br />
              <br /> <b>but who&apos;s best equipped to lend a hand?</b>
            </div>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.answer}>
            <div className={styles.content}>
              Answer:
              <br /> The personable Loan Officers at Bay Equity.
            </div>
          </div>
        </Reveal>
        <Reveal>
          <div className={styles.ticket}>
            <div className={styles.row}>
              <Slide bottom>
                <div className={styles.txtTicket}>
                  <div className={styles.text4}>
                    So, our team decided a two-
                    <br /> environment site was the ticket.
                  </div>
                  <div className={styles.text5}>
                    One constructed to get each audience settled, educated, and
                    quickly in touch with their Loan Officer.
                  </div>
                </div>
              </Slide>
              <div className={styles.imgComp}>
                <img src={comp2} srcSet={`${comp3} 3x`} />
              </div>
            </div>
            <div className={styles.row}>
              <div className={styles.imgEquity}>
                <img src={equity2} srcSet={`${equity3} 3x`} />
              </div>
              <div className={styles.txtTicket2}>
                <div className={styles.text6}>
                  One constructed to give Loan Officers a customizable space to
                  showcase the person behind the professional.
                </div>
              </div>
            </div>
          </div>
        </Reveal>
      </section>
    )
  }
}
