import React from "react"

import BEcase from "../../components/CaseStudies/BEcase"
import Layout from "../../components/Layout"
import SEO from "../../components/seo"

const BeCaseStudy = () => (
  <Layout backgroundColor={"white"} bannerBgColor="" isArchive>
    <SEO
      title="Thesis | Case study: Bay Equity"
      description="We refreshed the Bay Equity brand and built them a new website, bringing value to external customers and internal Loan Officers alike. Read the case study."
    />

    <BEcase />
  </Layout>
)

export default BeCaseStudy
