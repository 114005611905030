import React, { Component } from "react"

import styles from "./styles.module.less"
import Slide from "react-reveal/Reveal"
import Reveal from "react-reveal/Reveal"

import started1 from "./img/get-started-comp-1.png"
import started2 from "./img/get-started-comp-1@2x.png"
import started3 from "./img/get-started-comp-1@3x.png"
import wire1 from "./img/wireframe-comp.png"
import wire2 from "./img/wireframe-comp@2x.png"
import wire3 from "./img/wireframe-comp@3x.png"
import beGif from "./img/BayEquity_code_typing.gif"
import image1 from "./img/image.png"
import image2 from "./img/image@2x.png"
import image3 from "./img/image@3x.png"

export default class Tech extends Component {
  render() {
    return (
      <section className={styles.Tech}>
        <div className={styles.title}>
          <div className={styles.title1}>
            Actualizing ideas takes real-world
          </div>
          <div className={styles.title2}>TECH</div>
          <div className={styles.title3}>
            Created a fast-to-download, easy-to-update website by separating
            content management from website delivery.
          </div>
        </div>

        <div className={styles.row1}>
          <div className={styles.imgStart}>
            <img src={started2} srcSet={`${started3} 3x`} />
          </div>
          <Slide bottom>
            <div className={styles.text1}>
              Content is authored by a robust CMS and stored at
              GitHub&mdash;then built into a static site hosted on a global CDN.
            </div>
          </Slide>
        </div>
        <div className={styles.imgWire}>
          <img src={wire2} srcSet={`${wire3} 3x`} />
        </div>
        <div className={styles.row2}>
          <Slide bottom>
            <div className={styles.text2}>
              Dynamic features are provided by lightweight, serverless APIs,
              providing a stable, always-on environment with no up-front
              infrastructure costs and maximal technical flexibility.
            </div>
          </Slide>
          <div className={styles.content}>
            <img src={beGif} />
          </div>
        </div>
      </section>
    )
  }
}
